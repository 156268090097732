import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon, Strong, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до гольф-клубу Fairway Dreams
			</title>
			<meta name={"description"} content={"Там, де кожна гойдалка має значення!"} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до гольф-клубу Fairway Dreams"} />
			<meta property={"og:description"} content={"Там, де кожна гойдалка має значення!"} />
			<meta property={"og:image"} content={"https://start.novirexborn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://start.novirexborn.com/img/strategy.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://start.novirexborn.com/img/strategy.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://start.novirexborn.com/img/strategy.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://start.novirexborn.com/img/strategy.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://start.novirexborn.com/img/strategy.png"} />
			<meta name={"msapplication-TileImage"} content={"https://start.novirexborn.com/img/strategy.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.6) 0%,rgba(255, 255, 255, 0.6) 100%),url(https://start.novirexborn.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="140px 0 88px 0"
			quarkly-title="Hero-21"
			padding="88px 0 88px 0"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				flex-wrap="wrap"
				align-items="center"
				justify-content="center"
			/>
			<Text
				letter-spacing="2px"
				margin="0px 0px 16px 0px"
				color="--greyD2"
				font="--base"
				lg-width="100%"
				lg-text-align="center"
				text-align="center"
				text-transform="uppercase"
			>
				Відкрийте для себе Fairway Dreams
			</Text>
			<Text
				color="--darkL1"
				font="--headline1"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				margin="0px 0px 42px 0px"
				lg-width="100%"
				md-width="100%"
				lg-text-align="center"
				lg-margin="0px 0px 20px 0px"
				lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
			>
				Fairway Dreams
			</Text>
			<Text
				lg-text-align="center"
				text-align="center"
				margin="0px 0px 36px 0px"
				color="--darkL2"
				font="--lead"
				lg-width="100%"
			>
				Ласкаво просимо до гольф-клубу Fairway Dreams - ідеального місця для кожного гравця в гольф, який шукає дружню та захоплюючу атмосферу. Незалежно від того, чи ви досвідчений професіонал, чи тільки починаєте, наш клуб пропонує теплу та затишну атмосферу, де цінується кожен член, а кожна гра - це нова пригода.

			</Text>
			<Box display="flex" sm-flex-direction="column">
				<Link
					color="--light"
					font="--lead"
					sm-margin="0px 0px 20px 0px"
					sm-width="100%"
					hover-color="--dark"
					border-width="2px"
					border-style="solid"
					href="/contacts"
					text-decoration-line="initial"
					border-radius="75px"
					background="--color-dark"
					border-color="--color-dark"
					padding="10px 53px 10px 53px"
					margin="0px 24px 0px 0px"
					transition="background-color 0.3s linear 0s"
					hover-background="rgba(4, 8, 12, 0)"
					sm-text-align="center"
				>
					Звʼязатися
				</Link>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					as="h2"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Про нас
				</Text>
				<Text
					margin="8px 0px 20px 0px"
					font="--lead"
					text-align="center"
					color="--darkL2"
					width="70%"
					lg-width="100%"
					sm-text-align="left"
				>
					У гольф-клубі Fairway Dreams ми пишаємося тим, що пропонуємо першокласний досвід гри в гольф, який поєднує в собі красу ретельно доглянутого поля та товариську атмосферу пристрасних ентузіастів гольфу. Наш клуб створений для того, щоб покращити вашу гру та збагатити вашу любов до цього класичного виду спорту. Завдяки різноманітним складним лункам, мальовничій природі та найсучаснішому обладнанню Fairway Dreams - це більше, ніж просто гольф-клуб, це місце, де створюються чудові спогади.
				</Text>
				<Text
					as="h2"
					margin="40px 0px 0px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Чому варто обрати
					<br />
					{" "}Fairway Dreams?
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Винятковий дизайн поля
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Наше поле створено для гравців усіх рівнів майстерності, на ньому є рухомі фервеї, стратегічні бункери та незаймані зелені лунки.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Сімейна атмосфера
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Ми вітаємо членів клубу різного віку, що робить його чудовим місцем для сімейних прогулянок, де кожен може насолодитися грою.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Просунуті тренувальні можливості
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Оснащені найсучаснішими технологіями та професійними тренерами, наші тренувальні бази допоможуть вам вдосконалити свою гру.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text font="--lead" color="--darkL2" margin="0px 0px 0px 0px" sm-margin="4px 0px 0px 0px">
								<Strong>
								Енергійне співтовариство
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Приєднуйтесь до спільноти любителів гольфу, які підтримують один одного і поділяють пристрасть до цього виду спорту.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="center">
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 30px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Приєднуйтесь до нас на зеленому полі!
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				align-items="center"
				justify-content="center"
				lg-margin="0px 0px 16px 0px"
				text-align="center"
			>
				<Box
					padding="0px 0px 0px 16px"
					width="100%"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Ваша найкраща гра чекає на вас у гольф-клубі Fairway Dreams!
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});